import React from "react";

import Layout from "../../components/layout";
import {SupportHeader as Header} from "../../components/breadcrumb";
import {Callout} from "../../components/alert";
import {KeyHeader} from "../../components/typography";

const Page = () => (
    <Layout pageTitle="Support"
            pageNav={<Header/>}>

        <div className="row">
            {/* Technical Support */}
            <div className="col-12 col-md-7">
                <KeyHeader className="mt-0">Technical Support</KeyHeader>
                <p>
                    When you need help with any aspect of the library, please, send us an email to
                    {' '}<a href="mailto:support@sqlapi.com">support@sqlapi.com</a>.
                </p>
                <Callout heading="Response Time">
                    We are able to answer most of the questions in near real-time.
                    But, generally, allow us one business day to reply.
                </Callout>

                <p>
                    If some features are not available now but you want to
                    see them in SQLAPI++, please, send an email to
                    {' '}<a href="mailto:newfeature@sqlapi.com">newfeature@sqlapi.com</a>
                </p>

                <p>
                    To report a bug, please, send an email to
                    {' '}<a href="mailto:bugs@sqlapi.com">bugs@sqlapi.com</a>.
                    When authoring your email, please, provide us as
                    much information as possible, for example:
                </p>
                <ul>
                    <li>Detailed error description</li>
                    <li>OS version where your program is executed</li>
                    <li>SQLAPI++ version (see readme.txt in your installation root)</li>
                    <li>DBMS client version (for example, Oracle Instant Client 19.3)</li>
                    <li>DBMS server version (for example, Oracle 19c, Adaptive Server Enterprise 16.0, etc.)</li>
                    <li>Source code that produces an error</li>
                    <li>Database file or data unload (export) file, if applicable</li>
                </ul>

                <p>
                    For any questions regarding ordering (licensing) or upgrading, please, email us at
                    {' '}<a href="mailto:order@sqlapi.com">order@sqlapi.com</a>.
                </p>
            </div>

            {/* Mailing List */}
            <div className="col-12 col-md-4 offset-md-1">
                <KeyHeader className="mt-5 mt-md-0">Mailing List</KeyHeader>
                <p>
                    <strong>sqlapi-announce</strong>
                    <span> mailing list is hosted by </span>
                    <a href="http://www.freelists.org/list/sqlapi-announce">FreeLists</a>.
                </p>
                <Callout heading="Privacy">
                    This is a hidden list, which means that the members of the
                    list are available only to the the list administrator.
                </Callout>
                <p>
                    Major news and important changes in the project (including new releases)
                    are announced there.
                </p>
                <p>
                    To subscribe to the mailing list go to
                    {' '}<a href="http://www.freelists.org/list/sqlapi-announce">FreeList sqlapi-announce page</a>.
                </p>
            </div>
        </div>
    </Layout>
);

export default Page;
